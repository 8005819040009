import './App.css';
import { useEffect, useState } from 'react';
import { liffId } from './utils/Utils';
import liff from '@line/liff';
import Counter from './components/counter/Counter';
import iconCheckMark from './assets/image/checkmark.png';
import iconRedCross from './assets/image/redcross.png';
import iconExclamation from './assets/image/exclamation.png';
import iconLogo from './assets/image/logo.png';
import RatingReview from './components/rating-review/RatingReview';
import { createPointBill } from './services/Point';
import { createSalerPerformance } from './services/SalerPerformance';
import { updateUserLine } from './services/User';


function App() {

  const [loadSuccess, setLoadSuccess] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [profile, setProfile] = useState();

  const [success, setSuccess] = useState(false);
  const [isSaler, setIsSaler] = useState(false);
  const [point, setPoint] = useState(0);
  const [pointBalance, setPointBalance] = useState(0);
  const [pointPromotion, setPointPromotion] = useState(0);
  const [note, setNote] = useState();
  const [pointId, setPointId] = useState();

  const liffInit = async () => {
    await liff.init({ liffId: liffId });
    if (!liff.isLoggedIn()) {
      const redirectUri = window.location.href;
      liff.login({ redirectUri: redirectUri });
    } else {
      const info = await liff.getProfile();
      setProfile(info)
      localStorage.setItem('userId', info.userId)
    }
  }

  useEffect(() => {
    if (profile) {
      const pathname = window.location.pathname;
      const shopId = pathname.split('/')[4]
      const orderNo = pathname.split('/')[5]

      if (pathname.indexOf('products/get/remaxthailand') !== -1 && shopId && orderNo) {
        updateUserLine(profile.userId, shopId)
        setInvalidUrl(false)
        createPointBill(profile.userId, shopId, orderNo).then(res => {
          console.log(res)
          setSuccess(res.success);
          setIsSaler(res.saler)

          if (res.success) {
            setPoint(res.points)
            setPointPromotion((res.points - res.point_product || 0))
            setPointBalance(res.point_balance)
            setPointId(res.data.id)
          } else {
            setPointBalance(res.point_balance)
            setNote(res.message)
          }
          setTimeout(() => {
            setLoadSuccess(true)
          }, 1500);
        })
      } else {
        setInvalidUrl(true)
        setTimeout(() => {
          setLoadSuccess(true)
        }, 1500);
      }
    }
  }, [profile])

  useEffect(() => {
    liffInit();
  }, [])


  return (
    <>
      {!loadSuccess
        ? <div><Loading /></div>
        : <>
          {invalidUrl
            ? <ModalParam />
            : <>
              <div className="relative flex justify-center">
                <div className="w-[768px] relative overflow-hidden">
                  <div className="w-full h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-col items-center justify-center p-8">
                    <div className="w-full h-fit bg-white rounded-2xl shadow-xl p-8 scale-in-ver-center">
                      {isSaler
                        ? <ModalSaler success={success} note={note} />
                        : <>
                          {success
                            ? <ModalSuccess pointId={pointId} point={point} pointPromotion={pointPromotion} pointBalance={pointBalance} />
                            : <ModalFail note={note} pointBalance={pointBalance} />}
                        </>}
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          <div className="absolute bottom-2 w-full flex gap-2 justify-center">
            <img src={iconLogo} alt={iconLogo} className='w-5 h-5 rounded-md' />
            <div className="text-sm text-gray-500">Remax Happy Point</div>
          </div>
        </>
      }
    </>
  )
}

function ModalParam() {
  const onClose = () => {
    liff.closeWindow();
    window.close()
  }
  return (
    <div className="relative flex justify-center">
      <div className="w-[768px] relative overflow-hidden">
        <div className="w-full h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-col items-center justify-center p-8">
          <div className="w-full h-fit bg-white rounded-2xl shadow-xl p-8 scale-in-ver-center">
            <div className="flex flex-col items-center">
              <div className="w-1/3 mb-6">
                <img src={iconExclamation} alt="" />
              </div>
              <div className="flex flex-row items-center">
                <div className="text-center">URL ไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง</div>
              </div>
              <div className="mt-10 w-full flex flex-row items-center gap-4">
                <button className='w-full rounded-xl bg-yellow-500 text-white p-2' onClick={onClose}>ปิด</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ModalSaler({ success, note }) {
  const onClose = () => {
    liff.closeWindow();
    window.close()
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-1/3 mb-6">
        <img src={success ? iconCheckMark : iconRedCross} alt="" />
      </div>
      <div className="flex flex-row items-center">
        <div className="">{note}</div>
      </div>
      <div className="mt-10 w-full flex flex-row items-center">
        <button className={`w-full rounded-xl text-white p-2 ${success ? 'bg-green-500' : 'bg-red-500'}`} onClick={onClose}>ปิด</button>
      </div>
    </div>
  )
}

function ModalFail({ note, pointBalance }) {
  const onClose = () => {
    liff.closeWindow();
    window.close()
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-1/3 mb-6">
        <img src={iconRedCross} alt="" />
      </div>
      <div className="flex flex-row items-center">
        <div className="text-center">{note}</div>
      </div>
      <div className="mt-4 flex flex-row items-center gap-3 border w-full justify-center p-4 rounded-xl">
        <div className="">แต้มคงเหลือ</div>
        <div className="flex font-bold"><Counter number={pointBalance} /></div>
        <div className="">แต้ม</div>
      </div>
      <div className="mt-10 w-full flex flex-row items-center gap-4">
        <button className='w-full rounded-xl bg-red-500 text-white p-2' onClick={onClose}>ปิด</button>
      </div>
    </div>
  )
}

function ModalSuccess({ pointId = '', point = 0, pointPromotion = 0, pointBalance = 0 }) {
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const userId = localStorage.getItem('userId');
  const handleChange = (event) => {
    setComment(event.target.value)
  }
  const onClickReward = () => {
    window.location = 'https://happy-crm-platform.web.app/?liffId=1655877844-O2wdJL81&shopId=88888888'
  }
  const onSubmit = () => {
    setIsSubmit(true)
    if (pointId) {
      createSalerPerformance(pointId, userId, rating, comment).then(res => {
        console.log(res)
      })
    }
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-1/3 mb-6">
        <img src={iconCheckMark} alt="" />
      </div>
      {point > 0 && <div className="flex flex-row items-center gap-3 text-xl">
        <div className="">สะสมแต้มสำเร็จ ได้รับ</div>
        <div className="font-bold"><Counter number={point} /></div>
        <div className="">แต้ม</div>
      </div>}
      {pointPromotion > 0 && <div className="mt-2 flex flex-row items-center gap-3">
        <div className="">ได้รับแต้มโปรโมชั่น</div>
        <div className="flex font-bold">+<Counter number={pointPromotion} /></div>
      </div>}
      <div className="mt-4 flex flex-row items-center gap-3 border w-full justify-center p-4 rounded-xl">
        <div className="">แต้มคงเหลือ</div>
        <div className="flex font-bold"><Counter number={pointBalance} /></div>
        <div className="">แต้ม</div>
      </div>
      {!isSubmit
        ? <>
          <div className="mt-8">
            {rating <= 0 && <div className="text-center text-sm">คลิกเพื่อให้คะแนน</div>}
            <RatingReview rating={rating} setRating={setRating} />
          </div>
          <div className='mt-4 w-full flex flex-row gap-2'>
            <input type="text" className='w-full border rounded-xl py-2 px-4' placeholder='เขียนความคิดเห็น...' onChange={handleChange} />
            <button className='w-fit rounded-xl bg-green-500 text-white py-2 px-4' onClick={onSubmit}>ส่ง</button>
          </div>
        </>
        : <div className="mt-8 text-center text-sm bg-yellow-50 text-yellow-500 py-1 px-3 rounded-full">บันทึกความคิดเห็นสำเร็จ!</div>
      }
      <div className="mt-10 w-full flex flex-row items-center">
        <button className='w-full rounded-xl bg-green-500 text-white p-2' onClick={onClickReward}>แลกของรางวัล</button>
      </div>
    </div>
  )
}

function Loading() {
  return (
    <div className="relative flex justify-center">
      <div className="w-[768px] relative overflow-hidden">
        <div className="w-full h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-col items-center justify-center">
          <span className="loader"></span>
        </div>
      </div>
    </div>
  );
}

export default App;
