
console.log(process.env)

const liffId = process.env.REACT_APP_LIFF_ID;
const endPoint = process.env.REACT_APP_END_POINT;

console.log(liffId)
console.log(endPoint)

export {
    liffId,
    endPoint
};