import React from 'react'


export default function RatingReview({ rating, setRating }) {
  const [rate, setRate] = React.useState(0);

  const checkedStar = (value) => {
    setRate(value);
    setRating(value);
  }

  return (
    <div className="flex flex-row justify-center gap-1">
      {
        [1, 2, 3, 4, 5].map((e, index) => {
          return (
            <button key={index} onClick={() => checkedStar(e)} className="outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-8 h-8 lg:w-10 lg:h-10 text-yellow-400 ${e <= rate ? 'fill-yellow-400' : ''}`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
              </svg>
            </button>
          );
        })
      }
    </div>
  );
}