import axios from 'axios';
import { endPoint } from '../utils/Utils';

export async function getPointWithMatchingUser(userId, shopId) {
    return axios.get(`${endPoint}/user-line/getPointWithMatchingUser/${userId}/${shopId}`).then((response) => response.data);
}

export async function updateUserLine(userId, shopId) {
    const data = { "user_id": userId, "shop_id": shopId };
    await axios.post(`${endPoint}/user-line/updateUserLine`, data).then((response) => response.data);
}